import React, { useRef } from "react";
import ButtonList from "../components/ButtonList";
import VoiceVisual from "../assets/idle_frame_voice.png";
import BackButton from "../components/BackButton";
import VoicePlaceholder from "../assets/idle_voice_placeholder.png";
import InitialAudio from "../assets/initial.mp3";
import MicrophoneIcon from "../assets/microphone_icon.png";

// Combined list of questions and audio files
const questionAudioPairs = [
  {
    question:
      "What kind of warranty and maintenance plans does Isuzu offer for its Ready-to-Work models?",
    audio: "warranty",
  },
  {
    question:
      "What are the main differences between the Isuzu N Series and F Series trucks?",
    audio: "comparison",
  },
  {
    question:
      "Can you explain the safety features available on the latest Isuzu F Series models?",
    audio: "safety",
  },
  {
    question:
      "What transmission options are offered across the Isuzu N Series trucks?",
    audio: "transmission",
  },
  {
    question:
      "How does the Isuzu N Series handle off-road conditions with its AWD and 4x4 options?",
    audio: "offroad",
  },
];

const Voice = () => {
  const audioRef = useRef(null);

  // Function to play audio corresponding to the clicked question
  const handlePlayAudio = (audioFile) => {
    if (!audioRef.current) {
      console.error("Audio element is not initialized.");
      return;
    }

    try {
      audioRef.current.src = audioFile;

      audioRef.current.onerror = () => {
        console.error(`Failed to load or play the audio file: ${audioFile}`);
        alert("There was an error playing the audio file. Please try again.");
      };

      audioRef.current
        .play()
        .then(() => {
          console.log(`Playing audio: ${audioFile}`);
        })
        .catch((err) => {
          console.error("Error occurred during audio playback:", err);
          alert("Unable to play the audio file. Please try again.");
        });
    } catch (err) {
      console.error("Unexpected error during audio playback:", err);
      alert("An unexpected error occurred. Please try again.");
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center h-100 w-100 position-relative">
      <audio ref={audioRef} hidden></audio>
      <BackButton />
      <div className="col-4 d-flex align-items-center h-100">
        <ButtonList
          items={questionAudioPairs}
          screen={"voice"}
          onClickHandler={handlePlayAudio}
        />
      </div>
      <div className="col-8 idle-inner-frame">
        <div className="idle-voice-visual">
          {" "}
          <div className="isuzu-btn-container idle-voice-btn">
            <button className="text-decoration-none isuzu-btn voice-icon-container">
              <img
                src={MicrophoneIcon}
                alt="Microphone Icon"
                className="voice-icon"
              />
            </button>
          </div>
        </div>
        <img src={VoicePlaceholder} className="idle-voice-placeholder" />
      </div>
    </div>
  );
};

export default Voice;
