import React from "react";
import { Link } from "react-router-dom";

const ButtonList = ({
  items,
  screen,
  selectedId = -1,
  onClickHandler = () => {},
}) => {
  return (
    <ul className="list-unstyled button-list mb-0 ms-4 d-flex flex-column align-items-start justify-content-around">
      {items.map((item, index) => {
        switch (screen) {
          case "idle":
            return (
              <li className="isuzu-btn-container" key={index}>
                <div className="isuzu-btn-corner-top-left"></div>
                <Link
                  to={`/video/${index + 1}`}
                  className={
                    "ps-3 text-decoration-none isuzu-btn isuzu-btn-shape"
                  }
                >
                  {item}
                </Link>
              </li>
            );
          case "video":
            return (
              <li className="isuzu-btn-container" key={index}>
                <div className="isuzu-btn-corner-top-left"></div>
                <Link
                  to={`/video/${index + 1}`}
                  className={`ps-3 text-decoration-none isuzu-btn isuzu-btn-shape ${
                    index + 1 === selectedId && selectedId !== 0
                      ? ""
                      : "isuzu-btn-not-selected"
                  }`}
                >
                  {item}
                </Link>
              </li>
            );
          default:
            return (
              <li
                className="isuzu-btn-container isuzu-btn-canned-response"
                key={index}
              >
                <div className="isuzu-btn-corner-top-left"></div>
                <button
                  className={
                    "ps-3 text-decoration-none isuzu-btn isuzu-btn-shape"
                  }
                  onClick={() =>
                    onClickHandler(`/assets/audio/${item.audio}.mp3`)
                  }
                >
                  <div className="btn-multiline-text-wrapper">
                    {item.question}
                  </div>
                </button>
              </li>
            );
        }
      })}
    </ul>
  );
};

export default ButtonList;
