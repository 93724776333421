import React from "react";
import BackButtonIcon from "../assets/back_button_icon.png";
import { Link } from "react-router-dom";

const BackButton = () => {
  return (
    <div className="back-btn-container">
      <div className="back-btn-corner"></div>
      <Link
        to="/"
        className="text-decoration-none back-btn isuzu-btn isuzu-btn-shape"
      >
        <img src={BackButtonIcon} className="back-btn-icon" />
      </Link>
    </div>
  );
};

export default BackButton;
