import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Outlet,
} from "react-router-dom";
import Idle from "./pages/Idle";
import Video from "./pages/Video";
import Voice from "./pages/Voice";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />, // Layout with Header
    children: [
      { index: true, element: <Idle /> },
      { path: "video/:id", element: <Video /> },
      { path: "voice", element: <Voice /> },
    ],
  },
]);

function Layout() {
  return (
    <div className="screen-wrapper">
      <div className="screen-container">
        <Outlet />
      </div>
    </div>
  );
}

function App() {
  return <RouterProvider router={router} />;
}

export default App;
