import React from "react";
import EmergencyBrakingVideo from "../assets/demo-advanced-emergency-braking.mp4";
import DistanceWarningVideo from "../assets/demo-distance-warning-system.mp4";
import ElectronicStabilityControlVideo from "../assets/demo-electronic-stability-control.mp4";
import LaneDepartureWarningVideo from "../assets/demo-lane-departure-warning.mp4";
import TrafficMovementWarningVideo from "../assets/demo-traffic-movement-warning.mp4";
import BackButton from "../components/BackButton";
import VideoFrame from "../assets/video_frame.png";
import ButtonList from "../components/ButtonList";
import { useParams } from "react-router-dom";

const features = [
  "Advanced Emergency Braking",
  "Distance Warning System",
  "Electronic Stability Control",
  "Lane Departure Warning",
  "Traffic Movement Warning",
];

const videos = [
  EmergencyBrakingVideo,
  DistanceWarningVideo,
  ElectronicStabilityControlVideo,
  LaneDepartureWarningVideo,
  TrafficMovementWarningVideo,
];

const Video = () => {
  const { id } = useParams(); // Access the dynamic ID from the URL

  return (
    <div className="d-flex justify-content-center align-items-center h-100 w-100 position-relative">
      <BackButton />
      <div className="col-4 d-flex align-items-center h-100">
        <ButtonList
          items={features}
          screen={"video"}
          selectedId={parseInt(id)}
        />
      </div>
      <div className="col-8 video-frame-container">
        <img src={VideoFrame} className="video-frame" />
        <video
          className="isuzu-video"
          src={videos[parseInt(id) - 1]}
          autoPlay
        ></video>
      </div>
    </div>
  );
};

export default Video;
