import React, { useEffect, useState } from "react";
import ButtonList from "../components/ButtonList";
import VoiceVisual from "../assets/idle_frame_voice.png";
import VoicePlaceholder from "../assets/idle_voice_placeholder.png";
import { Link } from "react-router-dom";
import initialAudio from "../assets/initial.mp3";

const features = [
  "Advanced Emergency Braking",
  "Distance Warning System",
  "Electronic Stability Control",
  "Lane Departure Warning",
  "Traffic Movement Warning",
];

const Idle = () => {
  // const [audio] = useState(new Audio(initialAudio));

  // const handlePlayAudio = () => {
  //   audio.play().catch((error) => {
  //     console.error("Audio playback failed:", error);
  //   });
  // };
  useEffect(() => {
    const audio = new Audio("../assets/initial.mp3");
    audio.play().catch((error) => {
      console.error("Audio playback failed:", error);
    });

    // Optional: Cleanup if necessary
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, []); // Empty dependency array ensures it runs only once on mount

  return (
    <div className="idle-outer-frame">
      <div className="col-4 d-flex align-items-center">
        <ButtonList items={features} screen={"idle"} />
      </div>
      <div className="col-8 idle-inner-frame">
        <div className="idle-voice-visual">
          <div className="isuzu-btn-container idle-voice-btn">
            <Link
              to="/voice"
              className="text-decoration-none isuzu-btn voice-btn"
            >
              <div className="isuzu-btn-corner-top-left"></div>
              <div className="isuzu-btn-corner-bottom-right"></div>
              Talk to the truck
            </Link>
          </div>
        </div>
        <img src={VoicePlaceholder} className="idle-voice-placeholder" />
      </div>
    </div>
  );
};

export default Idle;
